<template>
  <div class="table-wrap">
    <div class="tool-bar">
        <div v-if="!!rowFilter" class="tool-cell">
          <el-input size="mini" v-model="searchKey" :placeholder="searchHint" clearable @keyup.native.enter="doSearch"
            @clear="doSearch"></el-input>
          <el-button type="primary" size="mini" @click="doSearch" icon="el-icon-search" />
        </div>
    </div>
    <el-table
      :header-cell-style="headercellstyle"
      :border="border"
      :data="tableDataShow"
      style="width: 100%"
      :size="tablesize"
      :height="tableheight"
      :row-key="rowkey"
      :row-class-name="rowClassName"
      :row-style="rowStyle"
      @selection-change="handleSelectionChange"
      @filter-change="filterchange"
      @header-click="headerclick"
      :cell-style="columnStyle"
      @select="selected"
      @select-all="selectall"
      @cell-mouse-enter="cellmouseenter"
      @cell-mouse-leave="cellmouseleave"
      @sort-change="sortChange"
      ref="myTable"
    >
      <template v-for="(item, index) in columns">
        <el-table-column
          :key="`index_${index}`"
          v-if="item.selection"
          type="selection"
          :reserve-selection="item.reserve"
          :align="item.align"
          :min-width="item.width"
        ></el-table-column>
        <el-table-column
          :key="`index_${index}`"
          v-else-if="item.index"
          type="index"
          :align="item.align"
          :min-width="item.width"
        ></el-table-column>
        <el-table-column
          :key="`index_${index}`"
          v-else-if="item.expand"
          type="expand"
          :align="item.align"
          :min-width="item.width"
        ></el-table-column>
        <el-table-column
          :key="`index_${index}`"
          v-else-if="item.filter"
          :align="item.align"
          :min-width="item.width"
          :prop="item.prop"
          :label="item.label"
          :formatter="item.formatter"
          :filters="item.filters"
          :filter-method="filterHandler"
          :header-align="item.headerAlign"
        ></el-table-column>
        <el-table-column
          v-else
          :key="`index_${index}`"
          :prop="item.prop"
          :formatter="item.formatter"
          :label="item.label"
          :align="item.align"
          :min-width="item.width"
          :header-align="item.headerAlign"
          :sortable="item.sortable"
        ></el-table-column>
      </template>
      <slot name="action"></slot>
    </el-table>
    <div v-if="isselectcount" class="selectvalue">
      <p>
        已选择<span class="selectcount">{{ selectcount }}</span
        >条数据
      </p>
    </div>
    <el-pagination
      class="pagination"
      v-if="isPaginationShow && pagination.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page.sync="pagination.current"
      :page-size="pagination.size"
      :total="data.length"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "ComTable",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    headercellstyle: {
      type: Object,
    },
    tableheight: {
      type: String,
      default: "400",
    },
    tablesize: {
      type: String,
      default: "medium",
    },
    border: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({
        current: 1,
        size: 20,
        total: 0,
      }),
    },
    isPaginationShow: {
      type: Boolean,
      default: true,
    },
    rowkey: {
      type: Function,
    },
    isselectcount:{
      type:Boolean,
      default:false
    },
    selectcount: {
      type: Number,
      default: 0
    },
    defaultSort: {
      type: Object,
    },
    rowStyle: {
      type: Function
    },
    rowFilter: {
      // (row, searchKey) => bool
      type: Function
    },
    searchHint: ''
  },
  data() {
    return {
        searchKey: '',
        tmpTableData: [],
    };
  },
  created() {},
  mounted() {},
  watch: {
    data(newV) {
      this.tmpTableData = newV
    }
  },
  computed: {
    tableDataShow() {
      const { size, current } = this.pagination
      return this.tmpTableData.slice(
        (current - 1) * size,
        current * size
      )
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pagination.size = val;
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.$emit("handleCurrentChange", current);
    },
    // handleSizeChange() {},
    // handleCurrentChange() {},
    headerclick(column, event) {
      console.log(event);
    },
    filterchange(value, row, column) {
      console.log(row);
    },
    filterHandler(value, row, column) {
      console.log(row);
      // const property = column["property"];
      // return row[property] === value;
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    selected(val, row) {
      this.$emit("selected", val, row);
    },
    selectall(val) {
      this.$emit("selectall", val);
    },
    cellmouseenter(val) {
      this.$emit("cellmouseenter", val);
    },
    cellmouseleave(val) {
      this.$emit("cellmouseleave", val);
    },
    columnStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label == "剩余时间") {
        if (row.row.RemainTimeStamp < 604800) {
          return "color:	#FF0000;";
        }
      }
    },
    rowClassName({ row, rowIndex }) {
      this.$emit("rowClassName", row, rowIndex);
    },
    sortChange({prop, order}) {
      // console.log(prop, order)
      this.$emit("sortChange", prop, order); 
    },
    doSearch() {
      if (!this.rowFilter || this.searchKey.trim() === '') {
        this.tmpTableData = this.data
        return
      }
      this.tmpTableData = this.data.filter(v => {
        return this.rowFilter(v, this.searchKey)
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.table-wrap {
  width: 100%;
  overflow: hidden;
  .tool-bar {
    margin: 4px 0px;
    width: 100%;
    .tool-cell {
      display: inline;
      padding-right: 8px;
      line-height: 28px;
      margin-right: 3px;
      // border-right: 2px solid lightgrey;
    }
    .el-input {
      width: 250px;
      margin-right: 3px;
    }
  }
  .pagination {
    height: 50px;
    margin-top: 30px;
    text-align: right;
    display: inline-block;
    float: right;
  }
  .selectvalue {
    float: left;
    margin-left: 10px;
    font-size: 14px;
    margin-top: 30px;
    .selectcount {
      font-weight: 600;
      color: rgb(88, 151, 242);
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>