<template>
  <div class="recharge-wrap">
    <div class="bread-wrap">
      <span class="bread-title">TK工具</span>
    </div>

    <div class="contents">
      <el-tabs
        v-model="activeName"
        style="margin-left: 20px; margin-right: 20px"
      >
        <el-tab-pane label="TK账号状态查询" name="linedetail">
          <el-alert type="warning" center :closable="false" title="由于 tiktok 官方调整，该工具暂时停止提供服务" show-icon="true"></el-alert>
          <div class="content">
            <div class="basicmessge">
              <!-- <span>目标使用区域：</span>
              <el-select
                v-model="region"
                class="search-input"
                size="mini"
                style="width: 150px"
              >
                <el-option v-for="reg in regions" :value="reg[0]" :label="reg[1]" :key="reg[0]"></el-option>
              </el-select>
              &nbsp; -->
              <span>TK账号ID：</span>
              <el-input
                v-model="currentTiktokId"
                class="search-input"
                clearable
                placeholder="请输入TK账号ID"
                size="mini"
                style="width: 250px"
                disabled
              />
              <el-button
                :loading="loading"
                class="filter-item"
                size="mini"
                style="margin-left: 10px; width: 110px"
                type="primary"
                @click="handleSearch"
                disabled
              >
                查询
              </el-button>
            </div>
          </div>
          <div class="stateContent">
            <tk-state-panel
              :tk-id="currentTiktokId"
              :key="globalId"
              :tk-state="tkState"
            ></tk-state-panel>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="TK服务端故障数反馈页面" name="linestability">
          <div class="content" v-if="isShow"></div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import TkStatePanel from "@/views/lines/TiktokTool/TkStatePanel.vue";
import { TkDestRegion } from "@/utils/constants.js";

export default {
  data() {
    return {
      activeName: "linedetail",
      isSearch: false,
      region: "tw-tp",
      currentTiktokId: "",
      loading: false,
      tkState: {},
      globalId: 0,
      regions: Object.entries(TkDestRegion),
    };
  },
  components: {
    TkStatePanel,
    ComTable,
  },
  activated() {
    this.getuserinfo();
  },
  methods: {
    handleSearch() {
      this.isSearch = false;
      if (this.currentTiktokId != null && this.currentTiktokId.length > 0) {
        this.globalId++;
        this.$nextTick(() => {
          this.getTkState();
        });
      } else {
        this.$message.error("请输入需要查询的TK账号ID~");
      }
    },
    getTkState() {
      this.loading = true;
      // 美区的中转 ip 被 tk 封控，暂时将美国区域的查询都转移到英国
      let region = this.region;
      if (region === "us-ca") {
        region = "uk-london";
      }
      this.$store
        .dispatch("line/getTkState", {
          tiktokId: this.currentTiktokId.trim(),
          region,
        })
        .then((res) => {
          if (res.retCode === 0) {
            this.parseTkState(res.state);
          } else {
            this.$message.error("查询失败：" + res.message);
          }
        })
        .catch((err) => {
          this.$message.error("查询失败：" + err);
        })
        .finally(() => {
          this.isSearch = true;
          this.loading = false;
        });
    },

    parseTkState(stateStr = "") {
      if (!stateStr) {
        this.$message.error("未查到相关信息！");
        return;
      }
      localStorage.setItem("tkState", stateStr);
      const state = JSON.parse(stateStr);
      this.tkState = state;
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
