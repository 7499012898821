<template>
  <div>
    <div class="panel" id="panel">
      <el-card>
        <div :key="i" :class="'ad' + (i || '') + ' ad'" v-for="(ad, i) in 3">
          {{ slogan }}
        </div>
        <el-descriptions :column="3" border size="small" title="用户基本信息">
          <el-descriptions-item label="用户名">{{ user.nickname }}</el-descriptions-item>
          <el-descriptions-item label="签名">{{ user.signature }}</el-descriptions-item>
          <el-descriptions-item label="账号所属区域">{{ user.region }}</el-descriptions-item>
          <el-descriptions-item label="语言">{{ user.language }}</el-descriptions-item>
          <el-descriptions-item label="TK 卖家">
            <el-tag size="small">{{ user.ttSeller }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="商户标签">
            <el-tag class="tag">
              {{ user.commerceUserInfo.category }}
            </el-tag>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :column="3" border size="small" title="账号活动区域">
          <el-descriptions-item>
            <template slot="label">
              区域
              <el-tooltip effect="light" content="以主页展示的近 30 条视频的发布地址来统计">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <el-tag :key="tag[0]" class="tag" v-for="tag in video.stats.locationSet" size="small">{{ tag[0] }}
            </el-tag>
          </el-descriptions-item>
          <!-- <el-descriptions-item>
          <template slot="label">
            是否匹配
            <el-tooltip effect="light" content="目标访问区域与日常活动是否匹配。若不匹配，则该账号在此区域访问，可能存在被风控的风险">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <el-tag :type="user.needFix ? '' : 'danger'" size="small">{{ user.needFix | bool }}</el-tag>
        </el-descriptions-item> -->
        </el-descriptions>

        <el-descriptions :column="3" border size="small" title="用户统计信息">
          <el-descriptions-item label="粉丝">{{ user.follower }}</el-descriptions-item>
          <el-descriptions-item label="关注">{{ user.following }}</el-descriptions-item>
          <el-descriptions-item label="点赞">{{ user.heart }}</el-descriptions-item>
          <el-descriptions-item label="已发视频">{{ user.video }}</el-descriptions-item>
        </el-descriptions>

        <!--      <el-descriptions :column="3" border size="small" title="直播信息">-->
        <!--        <el-descriptions-item label="开通 LiveStudio">-->
        <!--          <el-tag size="small">{{ live.liveStudio }}</el-tag>-->
        <!--        </el-descriptions-item>-->
        <!--        <el-descriptions-item label="封锁区域">-->
        <!--          <el-tag size="small">{{ live.blockArea }}</el-tag>-->
        <!--        </el-descriptions-item>-->
        <!--        <el-descriptions-item label="风险区域">-->
        <!--          <el-tag size="small">{{ live.riskArea }}</el-tag>-->
        <!--        </el-descriptions-item>-->
        <!--      </el-descriptions>-->

        <el-descriptions :column="3" border size="small" title="发布视频概览（近 30 条）">
          <el-descriptions-item label="视频数">{{ video.stats.videoCount + (video.hasMore ? '+' : '')
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="视频常被标记类型">
            <el-tag :key="tag[0]" class="tag" v-for="tag in video.stats.tagSet">
              <el-badge :value="tag[1]" :max="999">
                {{ tag[0] }}
              </el-badge>
            </el-tag>
          </el-descriptions-item> -->
          <el-descriptions-item label="常发布区域">
            <el-tag :key="tag[0]" class="tag" v-for="tag in video.stats.locationSet">
              <el-badge :value="tag[1]" :max="999">
                {{ tag[0] }}
              </el-badge>
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="广告视频数">
            <el-tag size="small">{{ video.stats.adCount }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card v-if="showVideoDetail">
        <div slot="header" class="clearfix">
          <span>{{ '发布视频详情' + (video.hasMore ? '(部分)' : '') }}</span>
        </div>
        <el-collapse>
          <el-collapse-item v-for="(v, i) in video.post" :name="v.id" :title="v.showTitle" :key="i">
            <el-descriptions :column="3" border size="small">
              <el-descriptions-item label="作者"> {{ v.author }}</el-descriptions-item>
              <el-descriptions-item label="描述">{{ v.desc }}</el-descriptions-item>
              <el-descriptions-item label="发布时间">{{ v.createTime }}</el-descriptions-item>
              <el-descriptions-item label="发布地区">{{ v.createLocation }}</el-descriptions-item>
              <el-descriptions-item label="视频格式">{{ v.format }}</el-descriptions-item>
              <el-descriptions-item label="广告">
                <el-tag size="small">{{ v.isAd }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="数据">
                {{ `播放：${v.stats.playCount}; 分享：${v.stats.shareCount}, \n评论：${v.stats.commentCount}` }}
              </el-descriptions-item>
              <el-descriptions-item label="视频标签">
                <el-tag class="tag" v-for="label in v.diversificationLabels" :key="label" size="small"
                  style="margin-right: 4px">
                  {{ label }}
                </el-tag>
              </el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
    <div class="panel">
      <div :key="i" :class="'ad' + (i || '') + ' ad'" v-for="(ad, i) in 3">
        {{ slogan }}
      </div>
      <el-card shadow="always">
        <div slot="header" class="clearfix">
          <span>视频数据统计(近 30 个)</span>
        </div>
        <div class="video-chart"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { LanguageMap, TiktokRegionMap } from "@/utils/constants.js";
import formatdate from "@/utils/format.js";

export default {
  name: "TkStatePanel",
  data: () => {
    return {
      showVideoDetail: false,
      slogan: '直播快（Pathlive)',
      user: {
        nickname: '',
        signature: '',
        commerceUserInfo: {
          commerceUser: true,
          category: "",
          categoryButton: false
        },
        needFix: '否',
        ttSeller: '否',
        region: '',
        language: '',
        following: 0,
        follower: 0,
        heart: 0,
        video: 0
      },
      region: {
        city: '',
        subDivisions: ''
      },
      live: {
        liveStudio: '否',
        blockArea: '否',
        riskArea: '否'
      },
      video: {
        post: [],
        hasMore: false,

        stats: {
          tagSet: [],
          locationSet: [],
          adCount: 0,
          videoCount: 0
        },

        statsMap: {}
      }
    }
  },
  props: {
    tkId: {
      require: true,
      type: String
    },
    tkState: {
      require: true,
      type: Object
    },
  },

  methods: {
    parseStateToDisplay() {
      const [userState, err] = tryAccess(this.tkState.UserModule.users, this.tkId)
      if (err) {
        this.$message.error('未查询到该账号信息！')
        return
      }

      this.user.nickname = userState.nickname
      this.user.signature = userState.signature
      this.user.ttSeller = bool(userState.ttSeller)
      this.user.commerceUserInfo.category = userState.commerceUserInfo.category || '非商户'
      const region = userState.region
      this.user.region = TiktokRegionMap[region] || region;

      const language = this.tkState.AppContext.lang
      this.user.language = LanguageMap[language] || language

      const [stateS, err2] = tryAccess(this.tkState.UserModule.stats, this.tkId)
      if (err2) {
        this.$message.error('未查询到该账号信息！')
        return
      }
      this.user.needFix = !stateS.needFix
      this.user.follower = stateS.followerCount
      this.user.following = stateS.followingCount
      this.user.heart = stateS.heartCount
      this.user.video = stateS.videoCount

      this.parseBizContext()
      this.parseVideo()
    },

    parseBizContext() {
      const bizCtx = this.tkState.BizContext.bizContext

      this.live.liveStudio = bool(bizCtx.liveStudioEnable)
      this.live.blockArea = bool(bizCtx.liveSuggestConfig.isBlockedArea)
      this.live.riskArea = bool(bizCtx.liveSuggestConfig.isRiskArea)
    },

    parseVideo() {
      const itemIndex = this.tkState.ItemList
      const itemModule = this.tkState.ItemModule
      const posts = itemIndex['user-post']
      this.video.hasMore = posts.hasMore

      let tagList = []
      let locationList = []
      let adCount = 0

      this.video.post = posts.list.map((vid, i) => {
        const video = itemModule[vid]
        const location = video.locationCreated
        const locationFormat = TiktokRegionMap[location] || location

        if (video.diversificationLabels) {
          tagList.push(...video.diversificationLabels)
        }
        locationList.push(locationFormat)

        if (video.isAd) {
          adCount++
        }

        const maxTitleLen = 100
        let title = video.desc
        if (title.length > maxTitleLen) {
          title = title.substring(0, maxTitleLen) + '...'
        }
        title = `${i + 1}. ${title}`

        this.video.statsMap[video.createTime] = video.stats

        return {
          showTitle: title,
          id: video.id,
          author: video.author,
          desc: video.desc,
          createTime: formatdate(video.createTime, 'YYYY/MM/DD HH:MM:SS'),
          createLocation: locationFormat,
          format: video.video.format,
          isAd: bool(video.isAd),
          stats: video.stats,
          diversificationLabels: video.diversificationLabels
        }
      })

      this.video.stats.tagSet = countByAndDesc(tagList)
      this.video.stats.locationSet = countByAndDesc(locationList)

      this.video.stats.adCount = adCount
      this.video.stats.videoCount = posts.list.length

      this.drawChart()
    },

    calculateChartData() {
      let xAxis = [];
      let playSeries = [];
      let commentSeries = [];
      let shareSeries = [];
      let collectSeries = [];
      let timeUnix = []
      let timeMovingRange = ['-']
      Object.keys(this.video.statsMap).forEach((time) => {
        const v = this.video.statsMap[time];
        playSeries.push(v.playCount);
        xAxis.push(formatdate(time, 'YYYY/MM/DD HH:MM:SS'));
        commentSeries.push(v.commentCount);
        shareSeries.push(v.shareCount);
        collectSeries.push(v.collectCount - 0);
        timeUnix.push(time)
      });

      for (let index = 0; index < timeUnix.length - 1; index++) {
        const diff = (timeUnix[index + 1] - timeUnix[index])
        const y = (diff / 60 / 60 / 24).toFixed(4) - 0
        timeMovingRange.push(y < 1 ? {
          value: y,
          itemStyle: { color: 'red' }
        } : y)
      }

      return {
        xAxis, playSeries, commentSeries, shareSeries, collectSeries, timeMovingRange
      }
    },

    drawChart() {
      const echartsInstance = this.$echarts.init(document.querySelector('.video-chart'))
      const {
        xAxis, playSeries, commentSeries, shareSeries, collectSeries, timeMovingRange
      } = this.calculateChartData()
      let option = {
        title: [
          { text: '播放量', left: '4%' },
          { text: '分享', left: '55%' },
          { text: '评论', left: '4%', top: '28%' },
          { text: '收藏', left: '55%', top: '28%' },
          { text: '距上个视频发布间隔（天）', left: '4%', top: '54%' }
        ],
        tooltip: {
          trigger: 'axis'
        },
        grid: [
          { width: '40%', height: '20%', left: '5%', top: '5%' },
          { width: '40%', height: '20%', right: '5%', top: '5%' },
          { width: '40%', height: '20%', left: '5%', top: '32%' },
          { width: '40%', height: '20%', right: '5%', top: '32%' },
          { width: '80%', height: '30%', left: '6%', bottom: '13%' }
        ],
        xAxis: [
          { gridIndex: 0, data: xAxis, type: 'category' },
          { gridIndex: 1, data: xAxis, type: 'category' },
          { gridIndex: 2, data: xAxis, type: 'category' },
          { gridIndex: 3, data: xAxis, type: 'category' },
          { gridIndex: 4, data: xAxis, type: 'category', axisLabel: { interval: 0, rotate: -90 } }
        ],
        yAxis: [
          { gridIndex: 0 },
          { gridIndex: 1 },
          { gridIndex: 2 },
          { gridIndex: 3 },
          { gridIndex: 4 }
        ],
        series: [
          {
            name: '播放',
            gridIndex: 0,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: playSeries,
            type: 'line'
          },
          {
            name: '分享',
            gridIndex: 1,
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: shareSeries,
            type: 'line'
          },

          {
            name: '评论',
            gridIndex: 2,
            xAxisIndex: 2,
            yAxisIndex: 2,
            data: commentSeries,
            type: 'line'
          },
          {
            name: '收藏',
            gridIndex: 3,
            xAxisIndex: 3,
            yAxisIndex: 3,
            data: collectSeries,
            type: 'line'
          },
          {
            name: '间隔（天）',
            gridIndex: 4,
            xAxisIndex: 4,
            yAxisIndex: 4,
            data: timeMovingRange,
            type: 'line'
          }
        ]
      }

      echartsInstance.setOption(option)
    }
  },

  watch: {
    tkState() {
      this.parseStateToDisplay()
    }
  },
  filters: {
    bool(b) {
      return b ? '是' : '否'
    }
  }
}

function countByAndDesc(list = []) {
  const map = list.reduce((acc, cur) => {
    if (acc.hasOwnProperty(cur)) {
      acc[cur]++
    } else {
      acc[cur] = 1
    }
    return acc
  }, {})
  return Object.entries(map).sort((a, b) => b[1] - a[1])
}

function bool(b) {
  return b ? '是' : '否'
}

function tryAccess(obj = {}, field = '') {
  if (!obj) {
    return [null, 'failed']
  }
  if (obj.hasOwnProperty(field)) {
    return [obj[field], null]
  } else {
    return [null, 'failed']
  }
}

</script>

<style lang="scss" scoped>
.panel {
  width: 70%;
  position: relative;
  margin-top: 10px;

  --marker-right: 40%;
  --marker-top: 50%;

  .ad1 {
    --marker-right: 60%;
    --marker-top: 30%;
  }

  .ad2 {
    --marker-right: 20%;
    --marker-top: 70%;
  }

  .ad {
    font-weight: bold;
    text-align: center;
    width: 300px;
    position: absolute;
    right: var(--marker-right);
    top: var(--marker-top);
    opacity: 0.15;
    rotate: -36deg;
    user-select: none;
    overflow: hidden;
    pointer-events: none;
  }

  .el-descriptions {
    margin-top: 10px
  }

  .tag {
    margin-right: 4px
  }

  .video-chart {
    // width: 1000px;
    height: 1200px
  }
}
</style>
